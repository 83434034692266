import {Particle} from "./Particle.js";
let lastDrawColor = "";
export class OrbitParticle extends Particle {
  constructor(x, y, size = 5, centerParticle, ctx) {
    super(x, y, size, "blue");
    this.rotation = 0;
    this.ctx = ctx;
    this.centerParticle = centerParticle;
    const yDistance = centerParticle.y - this.y;
    const xDistance = centerParticle.x - this.x;
    this.radius = Math.sqrt(Math.pow(Math.abs(xDistance), 2) + Math.pow(Math.abs(yDistance), 2));
    let lala = this.radius / this.ctx.canvas.width * 1;
    let CENTER_FACTOR = 0.4;
    this.targetSize = size * (lala * CENTER_FACTOR);
    this.size = this.targetSize - 4;
  }
  process() {
    this.rotation += 5e-3;
    if (this.targetSize != this.size) {
      if (this.targetSize > this.size) {
        this.size += 0.01;
      } else {
        this.size -= 0.01;
      }
    }
    [this.x, this.y] = this.rotate(this.centerParticle.x, this.centerParticle.y, this.x, this.y, (0.2 + this.radius * 15e-4) * 0.1);
  }
  isOffScreen() {
    return this.x + this.size < 0 || this.y + this.size < 0 || this.y - this.size > this.ctx.canvas.height || this.x - this.size > this.ctx.canvas.width;
  }
  draw() {
    if (this.isOffScreen()) {
      return false;
    }
    if (lastDrawColor != this.color) {
      lastDrawColor = this.color;
      this.ctx.fillStyle = this.color;
    }
    if (this.size > 0) {
      this.ctx.beginPath();
      this.ctx.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
      this.ctx.fill();
    }
    return true;
  }
  rotate(cx, cy, x, y, angle) {
    const radians = Math.PI / 180 * angle, cos = Math.cos(radians), sin = Math.sin(radians), nx = cos * (x - cx) + sin * (y - cy) + cx, ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [nx, ny];
  }
  easeInCubic(x) {
    return x * x * x;
  }
  easeOutSine(x) {
    return Math.sin(x * Math.PI / 2);
  }
}
