import {randomIntFromInterval} from "./ParticleRenderer.js";
export class Particle {
  constructor(x, y, size = randomIntFromInterval(3, 8), color = "blue") {
    this.size = 5;
    this.color = "blue";
    this.x = x;
    this.y = y;
    this.size = size;
    this.color = color;
  }
  draw() {
  }
}
